/*! приемущество спраа */ /* карточки окон слайдер*/ /* навбар*/

/*! CSS Used from: https://verest-okna.ru/css/site_global.css?crc=4042658147 */
#u1 {
  z-index: 160;
  width: 32px;
  height: 31px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  position: relative;
  /* margin-right: -10000px; */
  /* margin-left: 50%; */
  /* left: -223.49999999999994px; */
  background: transparent url(/images/osn-pr/renewable-energy.png) no-repeat
    center center;
  background-size: contain;
  margin-top: 0;
  margin-bottom: 1rem;
}

#u1-2 {
  z-index: 184;
  /* width: 311px; */
  min-height: 12px;
  background-color: transparent;
  color: #2196f3;
  text-align: left;
  font-size: 18px;
  line-height: 22px;
  font-family: ubuntu, sans-serif;
  font-weight: 400;
  position: relative;
  /* margin-right: -10000px; */
  /* margin-top: 4px; */
  /* margin-left: 50%; */
  /* left: -181.49999999999994px; */
  margin-top: 6px;
  font-size: 1.5rem;
}

#u1-3 {
  z-index: 232;
  /* width: 336px; */
  min-height: 59px;
  background-color: transparent;
  color: #1f2020;
  text-align: left;
  font-size: 16px;
  line-height: 19px;
  font-family: ubuntu, sans-serif;
  font-weight: 400;
  margin-bottom: 0.5rem;
  /* position: relative; */
  /* margin-right: -10000px; */
  /* margin-left: 50%; */
  /* left: -181.49999999999994px; */
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
  font-size: 1.2rem;
}

#u2 {
  z-index: 162;
  width: 32px;
  height: 31px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  position: relative;
  /* margin-right: -10000px; */
  /* margin-left: 50%; */
  /* left: -223.49999999999994px; */
  background: transparent url(/images/osn-pr/silence.png) no-repeat center
    center;
  background-size: contain;
  margin-top: 0;
  margin-bottom: 1rem;
}

#u2-2 {
  z-index: 192;
  width: 311px;
  min-height: 12px;
  background-color: transparent;
  color: #2196f3;
  text-align: left;
  font-size: 18px;
  line-height: 22px;
  font-family: ubuntu, sans-serif;
  font-weight: 400;
  position: relative;
  /* margin-right: -10000px; */
  margin-top: 6px;
  font-size: 1.5rem;
  /* margin-left: 50%; */
  /* left: -181.49999999999994px; */
}

#u2-3 {
  z-index: 240;
  /* width: 336px; */
  min-height: 59px;
  background-color: transparent;
  color: #1f2020;
  text-align: left;
  font-size: 16px;
  line-height: 19px;
  font-family: ubuntu, sans-serif;
  font-weight: 400;
  position: relative;
  margin-bottom: 0.5rem;
  /* margin-right: -10000px; */
  /* margin-left: 50%; */
  /* left: -181.49999999999994px; */
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
  font-size: 1.2rem;
}

#u3 {
  z-index: 164;
  width: 32px;
  height: 31px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  position: relative;
  /*  margin-right: -10000px;*/
  /* margin-left: 50%;*/
  /* left: -223.49999999999994px; */
  background: transparent url(/images/osn-pr/heat.png) no-repeat center center;
  background-size: contain;
  margin-top: 0;
  margin-bottom: 1rem;
}

#u3-2 {
  z-index: 200;
  /* width: 311px; */
  min-height: 12px;
  background-color: transparent;
  color: #2196f3;
  text-align: left;
  font-size: 18px;
  line-height: 22px;
  font-family: ubuntu, sans-serif;
  font-weight: 400;
  position: relative;
  /* margin-right: -10000px; */
  /* margin-left: 50%; */
  /* left: -181.49999999999994px; */
  margin-top: 7px;
  font-size: 1.5rem;
}

#u3-3 {
  z-index: 248;
  /* width: 336px; */
  min-height: 59px;
  background-color: transparent;
  color: #1f2020;
  text-align: left;
  font-size: 16px;
  line-height: 19px;
  font-family: ubuntu, sans-serif;
  font-weight: 400;
  position: relative;
  margin-bottom: 0.5rem;
  /* margin-right: -10000px; */
  /* margin-left: 50%; */
  /* left: -181.49999999999994px; */
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
  font-size: 1.2rem;
}

#u4 {
  z-index: 161;
  width: 32px;
  height: 31px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  position: relative;
  /*     margin-right: -10000px;
    margin-left: 50%;
    left: 166.50000000000006px; */
  background: transparent url(/images/osn-pr/door-handle.png) no-repeat center
    center;
  background-size: contain;
  margin-top: 0;
  margin-bottom: 1rem;
}

#u4-2 {
  z-index: 188;
  /* width: 324px; */
  min-height: 12px;
  background-color: transparent;
  color: #2196f3;
  text-align: left;
  font-size: 18px;
  line-height: 22px;
  font-family: ubuntu, sans-serif;
  font-weight: 400;
  position: relative;
  /*  margin-right: -10000px; */
  margin-top: 4px;
  /*  margin-left: 50%;
    left: 206.50000000000006px; */
  margin-top: 6px;
  font-size: 1.5rem;
}

#u4-3 {
  z-index: 236;
  /*  width: 308px; */
  min-height: 50px;
  background-color: transparent;
  color: #1f2020;
  text-align: left;
  font-size: 16px;
  line-height: 19px;
  font-family: ubuntu, sans-serif;
  font-weight: 400;
  position: relative;
  /*     margin-right: -10000px;
    margin-left: 50%;
    left: 206.50000000000006px; */
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
  font-size: 1.2rem;
}

#u5 {
  z-index: 163;
  width: 32px;
  height: 31px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  position: relative;
  /*  margin-right: -10000px; */
  margin-top: 2px;
  /*     margin-left: 50%;
    left: 166.50000000000006px; */
  background: transparent url(/images/osn-pr/color-palette.png) no-repeat center
    center;
  background-size: contain;
  margin-top: 0;
  margin-bottom: 1rem;
}

#u5-2 {
  z-index: 196;
  width: 324px;
  min-height: 12px;
  background-color: transparent;
  color: #2196f3;
  text-align: left;
  font-size: 18px;
  line-height: 22px;
  font-family: ubuntu, sans-serif;
  font-weight: 400;
  position: relative;
  /*  margin-right: -10000px; */

  /*     margin-left: 50%;
    left: 206.50000000000006px; */
  margin-top: 6px;
  font-size: 1.5rem;
}

#u5-3 {
  z-index: 244;
  /* width: 282px; */
  min-height: 59px;
  background-color: transparent;
  color: #1f2020;
  text-align: left;
  font-size: 16px;
  line-height: 19px;
  font-family: ubuntu, sans-serif;
  font-weight: 400;
  position: relative;
  /*     margin-right: -10000px;
    margin-left: 50%;
    left: 206.50000000000006px; */
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
  font-size: 1.2rem;
}

#u6 {
  z-index: 165;
  width: 32px;
  height: 31px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  position: relative;
  /* margin-right: -10000px; */
  margin-top: 2px;
  /* margin-left: 50%; */
  /* left: 166.50000000000006px; */
  background: transparent url(/images/osn-pr/green.png) no-repeat center center;
  background-size: contain;
  margin-top: 0;
  margin-bottom: 1rem;
}

#u6-2 {
  z-index: 204;
  /* width: 324px; */
  min-height: 12px;
  background-color: transparent;
  color: #2196f3;
  text-align: left;
  font-size: 18px;
  line-height: 22px;
  font-family: ubuntu, sans-serif;
  font-weight: 400;
  position: relative;
  /* margin-right: -10000px; */
  margin-top: 7px;
  /* margin-left: 50%; */
  /* left: 206.50000000000006px; */
  margin-top: 6px;
  font-size: 1.5rem;
}

#u6-3 {
  z-index: 252;
  /* width: 282px; */
  min-height: 59px;
  background-color: transparent;
  color: #1f2020;
  text-align: left;
  font-size: 16px;
  line-height: 19px;
  font-family: ubuntu, sans-serif;
  font-weight: 400;
  position: relative;
  /* margin-right: -10000px; */
  /* margin-left: 50%; */
  /* left: 206.50000000000006px; */
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
  font-size: 1.2rem;
}

/* карточки окон слайдер*/

#okna .block-navigation-tabs > div:first-child {
  border-left: 2px solid #ca1010;
}

#okna .block-navigation-tabs > .block-navigation-tab-active {
  background: #ca1010;
  color: white;
}
#okna .block-navigation-tabs > div {
  float: left;
  border: 2px solid #ca1010;
  padding: 10px 30px;
  font-size: 13pt;
  border-left: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.5s;
  flex: 1 1 auto;
  text-align: center;
}

#okna .block-navigation-tabs > div:first-child {
  border-left: 2px solid #ca1010;
}

#okna .nav-link active {
  background: #ca1010;
  color: white;
}
#okna .nav-link {
  float: left;
  border: 2px solid #ca1010;
  padding: 10px 30px;
  font-size: 13pt;
  border-left: none;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.5s;
  flex: 1 1 auto;
  text-align: center;
}

#okna .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #f44336;
}

/* #okna .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='DarkBlue' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  width: 40px;
  height: 40px;
}
#okna .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='DarkBlue' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  width: 40px;
  height: 40px;
}	
	
#okna .carousel-control-prev-icon1 {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='DarkBlue' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  width: 40px;
  height: 40px;
}
#okna .carousel-control-next-icon1 {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='DarkBlue' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  width: 40px;
  height: 40px;
}	 */

#okna .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #f44336;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #03a9f4;
}
.navbar-light .navbar-nav .nav-link {
  color: #03a9f4;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #3f51b5;
}
