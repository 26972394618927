/*! приемущество спраа */
#u1 {
  z-index: 160;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity= 100);
  background: url("images/osn-pr/renewable-energy.dd23a7c1.png") center / contain no-repeat;
  width: 32px;
  height: 31px;
  margin-top: 0;
  margin-bottom: 1rem;
  position: relative;
}

#u1-2 {
  z-index: 184;
  color: #2196f3;
  text-align: left;
  background-color: #0000;
  min-height: 12px;
  margin-top: 6px;
  font-family: ubuntu, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 22px;
  position: relative;
}

#u1-3 {
  z-index: 232;
  color: inherit;
  text-align: left;
  background-color: #0000;
  min-height: 59px;
  margin-bottom: .5rem;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2;
}

#u2 {
  z-index: 162;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity= 100);
  background: url("images/osn-pr/silence.04d34396.png") center / contain no-repeat;
  width: 32px;
  height: 31px;
  margin-top: 0;
  margin-bottom: 1rem;
  position: relative;
}

#u2-2 {
  z-index: 192;
  color: #2196f3;
  text-align: left;
  background-color: #0000;
  width: 311px;
  min-height: 12px;
  margin-top: 6px;
  font-family: ubuntu, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 22px;
  position: relative;
}

#u2-3 {
  z-index: 240;
  color: inherit;
  text-align: left;
  background-color: #0000;
  min-height: 59px;
  margin-bottom: .5rem;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2;
  position: relative;
}

#u3 {
  z-index: 164;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity= 100);
  background: url("images/osn-pr/heat.2cdf7970.png") center / contain no-repeat;
  width: 32px;
  height: 31px;
  margin-top: 0;
  margin-bottom: 1rem;
  position: relative;
}

#u3-2 {
  z-index: 200;
  color: #2196f3;
  text-align: left;
  background-color: #0000;
  min-height: 12px;
  margin-top: 7px;
  font-family: ubuntu, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 22px;
  position: relative;
}

#u3-3 {
  z-index: 248;
  color: inherit;
  text-align: left;
  background-color: #0000;
  min-height: 59px;
  margin-bottom: .5rem;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2;
  position: relative;
}

#u4 {
  z-index: 161;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity= 100);
  background: url("images/osn-pr/door-handle.3e79cb34.png") center / contain no-repeat;
  width: 32px;
  height: 31px;
  margin-top: 0;
  margin-bottom: 1rem;
  position: relative;
}

#u4-2 {
  z-index: 188;
  color: #2196f3;
  text-align: left;
  background-color: #0000;
  min-height: 12px;
  margin-top: 6px;
  font-family: ubuntu, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 22px;
  position: relative;
}

#u4-3 {
  z-index: 236;
  color: inherit;
  text-align: left;
  background-color: #0000;
  min-height: 50px;
  margin-bottom: .5rem;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2;
  position: relative;
}

#u5 {
  z-index: 163;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity= 100);
  background: url("images/osn-pr/color-palette.01e503cc.png") center / contain no-repeat;
  width: 32px;
  height: 31px;
  margin-top: 0;
  margin-bottom: 1rem;
  position: relative;
}

#u5-2 {
  z-index: 196;
  color: #2196f3;
  text-align: left;
  background-color: #0000;
  width: 324px;
  min-height: 12px;
  margin-top: 6px;
  font-family: ubuntu, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 22px;
  position: relative;
}

#u5-3 {
  z-index: 244;
  color: inherit;
  text-align: left;
  background-color: #0000;
  min-height: 59px;
  margin-bottom: .5rem;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2;
  position: relative;
}

#u6 {
  z-index: 165;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity= 100);
  background: url("images/osn-pr/green.bb09207e.png") center / contain no-repeat;
  width: 32px;
  height: 31px;
  margin-top: 0;
  margin-bottom: 1rem;
  position: relative;
}

#u6-2 {
  z-index: 204;
  color: #2196f3;
  text-align: left;
  background-color: #0000;
  min-height: 12px;
  margin-top: 6px;
  font-family: ubuntu, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 22px;
  position: relative;
}

#u6-3 {
  z-index: 252;
  color: inherit;
  text-align: left;
  background-color: #0000;
  min-height: 59px;
  margin-bottom: .5rem;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.2;
  position: relative;
}

#okna .block-navigation-tabs > .block-navigation-tab-active {
  color: #fff;
  background: #ca1010;
}

#okna .block-navigation-tabs > div {
  float: left;
  cursor: pointer;
  text-align: center;
  border: 2px solid #ca1010;
  border-left: none;
  flex: auto;
  padding: 10px 30px;
  font-size: 13pt;
  font-weight: 500;
  transition: all .5s;
}

#okna .block-navigation-tabs > div:first-child {
  border-left: 2px solid #ca1010;
}

#okna .nav-link active {
  color: #fff;
  background: #ca1010;
}

#okna .nav-link {
  float: left;
  cursor: pointer;
  text-align: center;
  border: 2px solid #ca1010;
  border-left: none;
  flex: auto;
  padding: 10px 30px;
  font-size: 13pt;
  font-weight: 500;
  transition: all .5s;
}

#okna .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #f44336;
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .nav-link {
  color: #03a9f4;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: #3f51b5;
}

@media (min-width: 768px) and (max-width: 991px) {
  #carousel-example .carousel-inner .active.col-md-4.carousel-item + .carousel-item + .carousel-item + .carousel-item {
    z-index: -1;
    visibility: visible;
    display: block;
    position: absolute;
    top: 0;
    right: -33.3333%;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  #carousel-example .carousel-inner .active.col-sm-6.carousel-item + .carousel-item + .carousel-item {
    z-index: -1;
    visibility: visible;
    display: block;
    position: absolute;
    top: 0;
    right: -50%;
  }
}

@media (min-width: 576px) {
  #carousel-example .carousel-item {
    margin-right: 0;
  }

  #carousel-example .carousel-inner .active + .carousel-item {
    display: block;
  }

  #carousel-example .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left), #carousel-example .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item {
    transition: none;
  }

  #carousel-example .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }

  #carousel-example .active.carousel-item-left + .carousel-item-next.carousel-item-left, #carousel-example .carousel-item-next.carousel-item-left + .carousel-item, #carousel-example .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item {
    visibility: visible;
    position: relative;
    transform: translate3d(-100%, 0, 0);
  }

  #carousel-example .carousel-inner .carousel-item-prev.carousel-item-right {
    z-index: -1;
    visibility: visible;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }

  #carousel-example .active.carousel-item-right + .carousel-item-prev.carousel-item-right, #carousel-example .carousel-item-prev.carousel-item-right + .carousel-item, #carousel-example .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item {
    visibility: visible;
    visibility: visible;
    display: block;
    position: relative;
    transform: translate3d(100%, 0, 0);
  }
}

@media (min-width: 768px) {
  #carousel-example .carousel-inner .active + .carousel-item + .carousel-item {
    display: block;
  }

  #carousel-example .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item {
    transition: none;
  }

  #carousel-example .carousel-inner .carousel-item-next {
    position: relative;
    transform: translate3d(0, 0, 0);
  }

  #carousel-example .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item {
    visibility: visible;
    position: relative;
    transform: translate3d(-100%, 0, 0);
  }

  #carousel-example .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item {
    visibility: visible;
    visibility: visible;
    display: block;
    position: relative;
    transform: translate3d(100%, 0, 0);
  }
}

@media (min-width: 991px) {
  #carousel-example .carousel-inner .active + .carousel-item + .carousel-item + .carousel-item {
    display: block;
  }

  #carousel-example .carousel-inner .carousel-item.active:not(.carousel-item-right):not(.carousel-item-left) + .carousel-item + .carousel-item + .carousel-item {
    transition: none;
  }

  #carousel-example .carousel-inner .active.col-lg-3.carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    z-index: -1;
    visibility: visible;
    display: block;
    position: absolute;
    top: 0;
    right: -25%;
  }

  #carousel-example .carousel-item-next.carousel-item-left + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    visibility: visible;
    position: relative;
    transform: translate3d(-100%, 0, 0);
  }

  #carousel-example .carousel-item-prev.carousel-item-right + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    visibility: visible;
    visibility: visible;
    display: block;
    position: relative;
    transform: translate3d(100%, 0, 0);
  }
}



/*# sourceMappingURL=index.9565e2b5.css.map */
